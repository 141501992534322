(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("instascan-umd"), require("react"));
	else if(typeof define === 'function' && define.amd)
		define(["instascan-umd", "react"], factory);
	else if(typeof exports === 'object')
		exports["react-instascan"] = factory(require("instascan-umd"), require("react"));
	else
		root["react-instascan"] = factory(root["instascan-umd"], root["react"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__2__) {
return 